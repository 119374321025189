<template>
   <div class="section no-pad-bot" id="index-banner">
    <div class="container">
      <br><br>
      <h1 class="header center orange-text">404 <br>Error </h1>
      </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorPageNotFound',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
